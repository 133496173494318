import { type SortField } from '~/areas/ai-hackerhouse/meeting-insight/types'
import { type ViewState } from '~/areas/project-management/pages/pm-v3/view-state'

import {
  type MeetingInsightsViewState,
  type MeetingsInsightsVersionedView,
} from '../../../areas/ai-hackerhouse/meeting-insight/view-types'

type AIHHViewState = ViewState | MeetingInsightsViewState

export function fromViewDefinition(view: MeetingsInsightsVersionedView): {
  view: AIHHViewState
} {
  const def = view.definition

  // const filterState: EntityFilterState = fromViewDefinitionFiltersToFilterState(
  //   def.itemType,
  //   def.filters
  // )

  const viewState: AIHHViewState = {
    $version: 6,
    columns: [],
    cardFields: [],
    page: 'meeting-insights',
    search: '',
    type: def.type,
    sortBy:
      def.sort.length === 0
        ? null
        : {
            field: def.sort[0].field as SortField,
            direction: def.sort[0].direction.toLowerCase() as 'asc' | 'desc',
          },
    groupBy: {
      fields: def.grouping.fields,
      order: def.grouping.order,
      hideEmpty: def.grouping.hideEmptyGroups,
      dateRange: def.grouping.dateRange ?? 'quarter',
    },
    viewId: view.id,
  }

  return {
    view: viewState,
  }
}
