import { entries } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'

import { useLookup } from '~/global/cache'
import { useProject } from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  type SetupProjectSearchParams,
  type SetupProjectUrlParams,
} from '../utils'

export function useSetupProjectModalUrl() {
  const {
    workspaceId: workspaceIdParam,
    folderId: folderIdParam,
    projectId: projectIdParam,
  } = useParams<SetupProjectUrlParams>()
  const { pathname, search } = useLocation()

  const projectFromUrlParam = useProject(projectIdParam)
  const lookup = useLookup()

  return useCallback(
    (params: Omit<SetupProjectSearchParams, 'project'> = {}) => {
      const urlParams = new URLSearchParams(search)
      urlParams.delete('task')

      const newParams = {
        ...params,
        // replace params based on the current route params
        forWorkspace:
          params.forWorkspace === workspaceIdParam
            ? undefined
            : params.forWorkspace,
        project: 'create',
        forFolder:
          params.forFolder ??
          urlParams.get('forFolder') ??
          folderIdParam ??
          projectFromUrlParam?.folderId,
      }

      for (const [key, value] of entries(newParams)) {
        if (key === 'forCustomField') {
          if (value == null || typeof value !== 'object') {
            urlParams.delete(key)
            continue
          }

          urlParams.set(key, JSON.stringify(value))
          continue
        }

        if (value) {
          urlParams.set(key, value as string)
        }
      }

      // Prevent creating a project with a folder in another workspace
      if (newParams.forFolder) {
        const folder = lookup('folders', newParams.forFolder)
        const workspaceId = urlParams.get('forWorkspace') ?? workspaceIdParam

        if (
          !folder ||
          (folder.targetType === 'WORKSPACE' && folder.targetId !== workspaceId)
        ) {
          Sentry.captureException(
            new Error(
              'Attempted to create project in folder from a different workspace'
            ),
            {
              level: 'warning',
              tags: {
                position: 'useSetupProjectModalUrl',
              },
              extra: {
                workspaceIdParam,
                folderIdParam,
                projectIdParam,
                newParams,
              },
            }
          )

          urlParams.delete('forFolder')
        }
      }

      return `${pathname}?${urlParams.toString()}`
    },
    [
      folderIdParam,
      lookup,
      pathname,
      projectFromUrlParam?.folderId,
      projectIdParam,
      search,
      workspaceIdParam,
    ]
  )
}

export function useSetupProjectModalUrlParams() {
  return useSearchParams<SetupProjectSearchParams>()
}
