import { PendingStatusSolid, XCircleSolid } from '@motion/icons'
import { type MeetingActionItemSchema } from '@motion/rpc-types'

import { ActionItemTitle } from '~/areas/ai-hackerhouse/components/action-item-title'
import { ApproveRejectButtons } from '~/areas/ai-hackerhouse/components/approve-reject-buttons'
import { ActionItemForm } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/action-item-form'
import { ConnectedActionItemModal } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/action-item-modal'
import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceFns, useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { ActionItemInlineSection } from './action-item-inline-section'

type ActionItemProps = {
  actionItem: MeetingActionItemSchema
  createMode?: boolean
  onAction?: (actionItemId: string) => void
}

export function ActionItem({
  actionItem,
  createMode = false,
  onAction,
}: ActionItemProps) {
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { getWorkspaceCanceledStatus } = useWorkspaceFns()
  const { data: taskSchema, isInitialLoading: taskIsLoading } = useTaskByIdV2(
    {
      id: actionItem.taskId,
    },
    {
      enabled: actionItem.taskId != null,
    }
  )

  const task = taskSchema?.type === 'NORMAL' ? taskSchema : null

  const isRejected = actionItem.hasBeenTriaged && actionItem.taskId == null
  const notPermittedWorkspace =
    actionItem.taskId != null && !taskIsLoading && task == null
  const disabled = isRejected || notPermittedWorkspace

  const taskStatus = useWorkspaceStatusById(task?.statusId)
  const isTaskCanceled =
    task != null
      ? task.statusId === getWorkspaceCanceledStatus(task.workspaceId).id
      : false

  const renderStatus = useCallback(() => {
    if (createMode) {
      return (
        <StatusBadge
          size='small'
          value={{
            name: 'Creating',
            color: 'gray',
            type: 'DEFAULT',
          }}
          hideTooltip
        />
      )
    }

    if (isRejected || isTaskCanceled) {
      return (
        <XCircleSolid className='size-3 text-semantic-neutral-icon-default' />
      )
    }

    if (actionItem.taskId != null) {
      return (
        <StatusBadge
          size='small'
          value={taskStatus}
          hideTooltip={notPermittedWorkspace}
        />
      )
    }

    return <PendingStatusSolid className='size-3' />
  }, [
    actionItem.taskId,
    createMode,
    isRejected,
    isTaskCanceled,
    notPermittedWorkspace,
    taskStatus,
  ])

  const handleOpenActionModal = () => {
    // Only open if it hasn't been triaged
    if (actionItem.hasBeenTriaged) {
      if (actionItem.taskId != null && task != null) {
        navigate(buildTaskModalUrl({ task: actionItem.taskId }))
      }

      return
    }

    setIsModalOpen(true)
  }

  const initialData = task ?? actionItem.taskData

  if (taskIsLoading) {
    return null
  }

  return (
    <ActionItemForm initialData={initialData}>
      <ConnectedActionItemModal
        actionItem={actionItem}
        visible={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
      <div className='flex justify-between items-center overflow-hidden gap-2'>
        <div className='flex flex-col gap-2 overflow-hidden flex-1 py-1'>
          <ActionItemTitle
            actionItem={actionItem}
            task={task}
            disabled={disabled}
            isCancelStatus={isRejected || isTaskCanceled}
            notPermittedWorkspace={notPermittedWorkspace}
            renderStatus={renderStatus}
            onClick={handleOpenActionModal}
          />

          <ActionItemInlineSection actionItem={actionItem} />
        </div>

        {!actionItem.hasBeenTriaged && (
          <ApproveRejectButtons actionItem={actionItem} onAction={onAction} />
        )}
      </div>
    </ActionItemForm>
  )
}
