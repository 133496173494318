import { UserAvatar } from '@motion/ui/base'

import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { useTeamMembers } from '~/global/hooks/team'
import { type CalendarEventOrganizer } from '~/types/CalendarEventOrganizer'
import { useMemo } from 'react'

type HostCellProps = {
  organizer: CalendarEventOrganizer
}

export function HostCell({ organizer }: HostCellProps) {
  const teamMembers = useTeamMembers()

  const name = useMemo(() => {
    const teamMember = teamMembers.find(
      (member) => member.user.email === organizer.email
    )
    return teamMember?.user.name ?? organizer.displayName ?? organizer.email
  }, [organizer.displayName, organizer.email, teamMembers])

  return (
    <CellText>
      <span className='flex items-center gap-1.5 overflow-hidden'>
        <UserAvatar name={name} id={name} />

        <span className='truncate'>{name}</span>
      </span>
    </CellText>
  )
}
