import { type TabItem, TabList } from '@motion/ui/base'

import { type ReactNode, useMemo } from 'react'

import { useViewState } from '../hooks/use-view-state'

type MeetingInsightsTabsProps = {
  children?: ReactNode
}

export function ConnectedMeetingInsightsTabs({
  children,
}: MeetingInsightsTabsProps) {
  const [viewState] = useViewState()

  const tabs = useMemo((): TabItem[] => {
    return [
      {
        name: 'All Calls',
        value: 'all-calls',
        content: 'All Calls',
      },
    ]
  }, [])

  return (
    <div className='px-4 border-b border-solid border-tab-border-default flex gap-2 items-center'>
      <div className='flex-1 h-9 overflow-hidden [&>[role="tablist"]>[role="tab"]]:h-9'>
        <TabList items={tabs} activeValue={viewState.type} />
      </div>
      {children}
    </div>
  )
}
