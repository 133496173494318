import {
  type MeetingActionItemSchema,
  type MeetingInsightsSchema,
} from '@motion/rpc-types'
import { createNoneId } from '@motion/shared/identifiers'
import { Button } from '@motion/ui/base'

import { DateTime } from 'luxon'
import { useState } from 'react'

import { ActionItem } from './action-item'
import { ActionItemList } from './action-item-list'
import { SectionCollapsible } from './section-collapsible'
import { UserRating } from './user-rating'

type ActionItemSectionProps = {
  meetingInsights: MeetingInsightsSchema
}

export function ActionItemsSection({
  meetingInsights,
}: ActionItemSectionProps) {
  return <ActionItemSectionBody meetingInsights={meetingInsights} />
}

function ActionItemSectionBody({ meetingInsights }: ActionItemSectionProps) {
  const [newActionItem, setNewActionItem] =
    useState<MeetingActionItemSchema | null>(null)
  const createNewActionItem = (meetingInsights: MeetingInsightsSchema) => {
    setNewActionItem(createFakeActionItem(meetingInsights))
  }

  return (
    <SectionCollapsible title='Action Items'>
      <div className='flex flex-col gap-6'>
        <ActionItemList actionItems={meetingInsights.actionItems} />
        {newActionItem && (
          <ActionItem
            actionItem={newActionItem}
            onAction={() => {
              setNewActionItem(null)
            }}
            createMode
          />
        )}
      </div>
      <div className='self-start'>
        {newActionItem == null && (
          <Button
            sentiment='neutral'
            variant='outlined'
            size='small'
            onClick={() => createNewActionItem(meetingInsights)}
          >
            Add action item
          </Button>
        )}
      </div>
      <div className='self-end'>
        <UserRating
          label='How accurate were these action items?'
          section='actionItems'
          meetingInsightsId={meetingInsights.id}
        />
      </div>
    </SectionCollapsible>
  )
}

function createFakeActionItem(
  meetingInsights: MeetingInsightsSchema
): MeetingActionItemSchema {
  return {
    id: createNoneId('action-item'),
    taskId: null,
    meetingInsightsId: meetingInsights.id,
    hasBeenTriaged: false,
    taskData: {
      name: '',
      dueDate: DateTime.now().plus({ days: 1 }).toISO(),
      duration: 0,
    },
  }
}
