import {
  FilledChevronDownSolid,
  FilledChevronUpSolid,
  RecordingGradientSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { ConnectedNameSearchButton } from '~/areas/project-management/pages/pm-v3/components'
import { type ReactNode, useState } from 'react'

import { ConnectedGroupSortButton } from './connected-group-sort-button'
import { ConnectedGroupingButton } from './connected-grouping-button'
import { ConnectedSortButton } from './connected-sort-button'
import { ConnectedMeetingInsightsTabs } from './meeting-insights-tabs'

export function ConnectedHeader() {
  const [subHeaderVisible, setSubHeaderVisible] = useState(true)

  return (
    <HeaderBar>
      <PaddedContainer>
        <TitleBarTitle>Meeting Recordings</TitleBarTitle>
      </PaddedContainer>
      <ConnectedMeetingInsightsTabs>
        <ConnectedNameSearchButton />
        <Button
          size='small'
          variant='muted'
          sentiment='neutral'
          onClick={() => setSubHeaderVisible((prev) => !prev)}
        >
          {subHeaderVisible ? (
            <FilledChevronUpSolid />
          ) : (
            <FilledChevronDownSolid />
          )}
          {templateStr('{{action}} options', {
            action: subHeaderVisible ? 'Hide' : 'Show',
          })}
        </Button>
      </ConnectedMeetingInsightsTabs>

      {subHeaderVisible && (
        <PaddedContainer className='flex flex-col gap-2'>
          <div className='flex items-center gap-2'>
            <ConnectedGroupingButton />
            <ConnectedGroupSortButton />
            <ConnectedSortButton />
          </div>
        </PaddedContainer>
      )}
    </HeaderBar>
  )
}

const HeaderBar = classed('div', {
  base: `
  flex flex-col gap-3
  py-4
  `,
})

const PaddedContainer = classed('div', 'px-4')

type TitleBarTitleProps = {
  children?: ReactNode
}

const TitleBarTitle = (props: TitleBarTitleProps) => {
  return (
    <TitleBarHeader>
      <div className='inline-flex flex-row gap-1.5 items-center'>
        <RecordingGradientSolid className='size-4' />

        {props.children}
      </div>
    </TitleBarHeader>
  )
}

export const TitleBarHeader = classed('h2', {
  base: `
    text-semantic-neutral-text-default
    font-semibold
    text-base
  `,
})
