import { StatusCompletedSolid } from '@motion/icons'
import { type MeetingInsights } from '@motion/rpc-types'
import { PopoverTrigger } from '@motion/ui/base'
import { StatusIcon } from '@motion/ui/pm'
import { templateStr } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'

import { CellAction } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { type ReactNode } from 'react'

import { ActionItemList } from '../../components/action-item-list'

type ActionItemsStatus = 'completed' | 'needs-action' | 'empty'

type ActionItemsCellProps = {
  meetingInsights: MeetingInsights
}

export function ActionItemsCell({ meetingInsights }: ActionItemsCellProps) {
  const disabled =
    meetingInsights.actionItems.filter((a) => !a.hasBeenTriaged).length === 0
  const status = getStatus(meetingInsights)

  const { label, icon } = renderDetailLookup(status)(meetingInsights)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <ActionItemDropdown meetingInsights={meetingInsights} />
      )}
    >
      <CellAction disabled={disabled}>
        <div className='flex items-center gap-2 [&>[data-icon]]:size-4'>
          {icon}
          <span className='text-semantic-neutral-text-default'>{label}</span>
        </div>
      </CellAction>
    </PopoverTrigger>
  )
}

type ActionItemDropdownProps = {
  meetingInsights: MeetingInsights
}

function ActionItemDropdown({ meetingInsights }: ActionItemDropdownProps) {
  // Filter out action items that have already been triaged into a task
  const actionItemsWithoutTask = meetingInsights.actionItems.filter(
    (item) => item.taskId == null
  )

  return (
    <div className='w-[450px] rounded border-dropdown-border shadow-md p-3'>
      <ActionItemList actionItems={actionItemsWithoutTask} />
    </div>
  )
}

function getStatus(meetingInsights: MeetingInsights): ActionItemsStatus {
  if (meetingInsights.actionItems.length === 0) {
    return 'empty'
  }

  return meetingInsights.actionItems.every((item) => item.hasBeenTriaged)
    ? 'completed'
    : 'needs-action'
}

const renderDetailLookup = createLookup<
  Record<
    ActionItemsStatus | 'default',
    (meetingInsights: MeetingInsights) => {
      label: ReactNode
      icon?: ReactNode
    }
  >
>({
  completed: () => {
    return {
      label: 'Completed',
      icon: <StatusCompletedSolid />,
    }
  },
  'needs-action': (meetingInsights) => {
    const needsReviewCount = meetingInsights.actionItems.filter(
      (item) => !item.hasBeenTriaged
    ).length

    return {
      label: templateStr(`{{count}} to review`, {
        count: <strong>{needsReviewCount}</strong>,
      }),
      icon: (
        <StatusIcon
          item={{
            color: '#FFB224',
            name: 'Needs Review',
            type: 'DEFAULT',
          }}
        />
      ),
    }
  },
  empty: () => {
    return {
      label: '-',
    }
  },
  default: () => {
    return {
      label: '-',
    }
  },
})
