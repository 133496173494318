import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import type { FolderSchema } from '@motion/zod/client'

import { useFindFolderItem } from '~/areas/folders/hooks'
import {
  BaseEditFolderModal,
  type SaveHandlerData,
} from '~/areas/modals/folders/components/base-edit-folder-modal'
import { useLookup } from '~/global/cache'
import { useUpdateFolder, useUpdateItemInFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useMemo } from 'react'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'edit-folder': {
      folderId: FolderSchema['id']
    }
  }
}

export const EditFolderModal = ({
  folderId,
  close,
}: ModalTriggerComponentProps<'edit-folder'>) => {
  const findFolderItem = useFindFolderItem()
  const lookup = useLookup()
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()

  const match = useMemo(() => {
    const folder = lookup('folders', folderId)
    const path = findFolderItem(({ itemId }) => itemId === folderId)

    if (!folder || !path || path.length === 0) return null

    // Remove the last item which is the root workspaces object
    path.pop()

    const [folderItem, ...rest] = path

    // The workspace will be the last item
    const workspaceFolder = rest[rest.length - 1]
    const workspace = workspaceFolder
      ? lookup('folders', workspaceFolder.itemId)
      : undefined

    const parentFolder = rest.length >= 2 ? rest[0] : undefined

    return {
      itemId: folderItem.id,
      folder,
      parentFolderId: parentFolder?.itemId ?? workspaceFolder?.itemId,
      workspaceId: workspace?.targetId,
    }
  }, [findFolderItem, folderId, lookup])

  if (!match) {
    Sentry.captureException(new Error('Could not match folder to edit'), {
      extra: {
        folderId,
      },
      tags: {
        position: 'EditFolderModal',
      },
    })

    showErrorToast('Unexpected error occurred')

    return void close()
  }

  const { itemId, folder, parentFolderId, workspaceId } = match

  const handleOnSave = async ({
    parentFolderId: newParentFolderId,
    folderName,
    selectedColor,
  }: SaveHandlerData) => {
    if (folderName !== folder.name) {
      recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
        updated: 'NAME',
        location: 'MODAL',
      })
    }

    if (selectedColor !== folder.color) {
      recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
        updated: 'COLOR',
        location: 'MODAL',
      })
    }

    try {
      await updateFolder({
        folderId,
        name: folderName,
        color: selectedColor,
      })

      if (newParentFolderId !== parentFolderId) {
        // Folder was moved
        await updateItemInFolder({
          itemId,
          parentFolderId: newParentFolderId,
        })
      }

      showToast('success', 'Folder updated')

      close()
    } catch (e) {
      showErrorToast(e, 'Could not update folder')

      Sentry.captureException(
        new Error('Failed to update folder', { cause: e }),
        {
          extra: {
            folderId,
            folderName,
            selectedColor,
          },
          tags: {
            position: 'EditFolderModal',
          },
        }
      )
    }
  }

  return (
    <BaseEditFolderModal
      close={close}
      folderId={folderId}
      initialFolderName={folder.name ?? undefined}
      initialSelectedColor={folder.color ?? undefined}
      initialSelectedParentFolderId={parentFolderId}
      initialSelectedWorkspaceId={workspaceId}
      onSave={handleOnSave}
    />
  )
}
