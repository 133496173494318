import {
  ConnectedHeader,
  ConnectedMeetingInsightsList,
} from '~/areas/ai-hackerhouse/meeting-insight'

export function MeetingInsightsViewPage() {
  return (
    <div className='grid grid-rows-[auto_1fr] overflow-hidden h-full'>
      <ConnectedHeader />

      <div className='min-w-0 grid min-h-0'>
        <ConnectedMeetingInsightsList />
      </div>
    </div>
  )
}
