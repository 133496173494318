import { formatTimeRange } from '@motion/ui-logic'

import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'

type TimeRangeCellProps = {
  startTime: string
  endTime: string
}

export const TimeRangeCell = ({ startTime, endTime }: TimeRangeCellProps) => {
  const formatted = formatTimeRange(startTime, endTime)

  return (
    <CellText>
      <span className='truncate'>{formatted}</span>
    </CellText>
  )
}
