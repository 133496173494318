import { type MeetingActionItem } from '@motion/rpc-types'
import { byValue, orderedAtEnd } from '@motion/utils/array'

import { useMemo } from 'react'

import { ActionItem } from './action-item'

type ActionItemListProps = {
  actionItems: MeetingActionItem[]
}

export function ActionItemList({ actionItems }: ActionItemListProps) {
  const sortedActionItems = useMemo(
    () =>
      actionItems.sort(
        byValue(
          (item) =>
            item.taskId != null
              ? 'accepted'
              : item.hasBeenTriaged
                ? 'rejected'
                : 'pending',
          orderedAtEnd(['pending', 'accepted', 'rejected'])
        )
      ),
    [actionItems]
  )

  return (
    <div className='flex flex-col gap-6 min-w-0'>
      {sortedActionItems.map((actionItem) => (
        <ActionItem key={actionItem.id} actionItem={actionItem} />
      ))}
    </div>
  )
}
