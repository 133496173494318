import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { time } from '@motion/utils/debug'

import { LoadingPage } from '~/areas/project-management/pages/pm-revamp/common/loading-page'
import {
  buildTreeGroup,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { TreeList } from '~/areas/project-management/pages/pm-v3/tree-list'
import { useMemo } from 'react'

import { useMeetingInsightsQuery } from '../../hooks/rpc'
import {
  type GroupByTypes,
  type GroupDefinition,
  useAvailableGroups,
} from '../grouping'
import { useSortBy } from '../grouping/use-sort-by'
import { useTreeGroupOverride } from '../grouping/use-tree-group-override'
import { useMeetingInsightsColumns } from '../hooks/use-meeting-insights-columns'
import { useViewState } from '../hooks/use-view-state'
import { type MeetingTreeListRowValueType } from '../types'

export function ConnectedMeetingInsightsList() {
  // TBH might be worth just have a meeting insights version of this?
  const [viewState] = useViewState()
  const { all: groups } = useAvailableGroups()
  const sortBy = useSortBy()

  const { data } = useMeetingInsightsQuery({
    filters: {
      meetingBotStatus: {
        operator: 'in',
        value: ['COMPLETED'],
      },
    },
    sortBy: sortBy,
  })

  const filteredData = useMemo(() => {
    if (!data) return []

    if (!viewState.search) return data
    return data.filter((x) => {
      if (!x.eventData) return true

      return (
        x.eventData.title
          .toLowerCase()
          .includes(viewState.search.toLowerCase()) ||
        (x.eventData.organizer?.displayName ?? x.eventData.organizer?.email)
          ?.toLowerCase()
          .includes(viewState.search.toLowerCase())
      )
    })
  }, [data, viewState.search])

  const groupDefinitions = useMemo(() => {
    return viewState.groupBy.fields
      .map((g) => groups.find((def) => def.type === g.key))
      .filter(Boolean)
  }, [groups, viewState.groupBy.fields])

  if (data == null) {
    return <LoadingPage />
  }

  if (data.length === 0) {
    return (
      <div className='grid place-items-center w-full h-full'>
        <span className='text-semantic-neutral-text-default font-semibold text-center'>
          No meeting insights found
        </span>
      </div>
    )
  }

  return (
    <MeetingInsightsList
      groupBy={groupDefinitions}
      meetingInsights={filteredData}
    />
  )
}

type MeetingInsightsListProps = {
  groupBy: GroupDefinition<MeetingInsightsSchema>[]
  meetingInsights: MeetingInsightsSchema[]
}

function MeetingInsightsList(props: MeetingInsightsListProps) {
  const overrides = useTreeGroupOverride()
  const [viewState] = useViewState()
  const { byType } = useAvailableGroups()

  const sortGroupsByFns = useMemo(() => {
    return viewState.groupBy.fields.reduce(
      (acc, group) => {
        const key = group.key as GroupByTypes
        const groupSortFn = byType[key]?.sortBy

        if (groupSortFn) {
          acc[key] = (l, r) => {
            if (viewState.groupBy.order.by === 'desc') {
              return groupSortFn(r.value, l.value)
            }

            return groupSortFn(l.value, r.value)
          }
        }

        return acc
      },
      {} as Record<GroupByTypes, (l: GroupedNode, r: GroupedNode) => number>
    )
  }, [byType, viewState.groupBy.fields, viewState.groupBy.order.by])

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy as any, overrides)
        .add('meetingInsights', props.meetingInsights)
        .buildTree<GroupedNode<MeetingTreeListRowValueType>>({
          hideEmptyGroups: true,
          sortGroupFns: sortGroupsByFns,
        })
    })
  }, [overrides, props.groupBy, props.meetingInsights, sortGroupsByFns])

  const columns = useMeetingInsightsColumns()

  return <TreeList columns={columns} tree={grouped} />
}
