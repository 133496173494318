import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { type CalendarEventAttendeeSchema } from '@motion/rpc-types'
import { PopoverTrigger, UserCard } from '@motion/ui/base'
import { createLookupBy } from '@motion/utils/object'

import { AttendeeBadge, PrettyAttendees } from '~/areas/event/components'
import {
  CellAction,
  CellText,
} from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { AppWorkspaceContext } from '~/global/contexts'

type AttendeesCellProps = {
  attendees: CalendarEventAttendeeSchema[]
}

export function AttendeesCell({ attendees }: AttendeesCellProps) {
  if (attendees.length === 0) {
    return (
      <CellText>
        <span className='text-semantic-neutral-text-subtle'>None</span>
      </CellText>
    )
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => <AttendeesDropdownContent attendees={attendees} />}
    >
      <CellAction>
        <PrettyAttendees attendees={attendees} size='normal' hideRsvpStatus />
      </CellAction>
    </PopoverTrigger>
  )
}

type AttendeesDropdownContentProps = {
  attendees: CalendarEventAttendeeSchema[]
}

function AttendeesDropdownContent({
  attendees,
}: AttendeesDropdownContentProps) {
  const userLookupByEmail = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => {
      return createLookupBy(
        state.users.all.filter((user) =>
          attendees.some((attendee) => attendee.email === user.email)
        ),
        (item) => item.email
      )
    }
  )

  return (
    <div className='min-w-[180px] max-h-[400px] px-3 py-2 flex flex-col gap-3'>
      {attendees.map((attendee) => {
        const teamMember = userLookupByEmail[attendee.email]
        const copy = { ...attendee }

        if (teamMember != null) {
          copy.displayName = teamMember.name
        }

        const name = copy.displayName

        return (
          <div className='shrink-0' key={copy.email}>
            <UserCard
              name={name != null ? name : copy.email}
              icon={<AttendeeBadge hideRsvpStatus value={copy} />}
              tag={copy.isOrganizer ? 'Host' : null}
            />
          </div>
        )
      })}
    </div>
  )
}
