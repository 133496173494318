import {
  SharedStateProvider,
  type StateKey,
  useSharedStateContext,
} from '@motion/react-core/shared-state'
import { classed } from '@motion/theme'
import { DB, SharedStatePersister } from '@motion/web-common/storage'

import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { type PageParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { ViewStateKey } from '~/areas/project-management/pages/pm-v3/view-state'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const persistKeysFilter = (key: StateKey<any>) => [ViewStateKey].includes(key)

export function MeetingInsightsShell() {
  // NOTE: Meeting insights may need its own version of the usePageData
  const route = usePageData()

  return (
    <SharedStateProvider name='meeting-insights-view'>
      <Container>
        <SharedStatePersister
          key={route.stateKey}
          prefix={route.stateKey}
          store={DB.state}
          keyFilter={persistKeysFilter}
        />

        <SyncRoute route={route} />
        <Outlet />
      </Container>
    </SharedStateProvider>
  )
}

const Container = classed('div', {
  base: `
  w-full h-full overflow-hidden
  bg-calendar-bg-default
  isolate
  relative
  `,
})

type SetRouteParamsProps = {
  route: PageParams
}

function SyncRoute(props: SetRouteParamsProps) {
  const ctx = useSharedStateContext()
  useEffect(() => {
    props.route.state.forEach((value, key) => {
      ctx.set(key, value)
    })
    // We only want to reset these values when the route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, props.route.stateKey])

  return null
}
