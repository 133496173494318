import { byProperty, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import type {
  FolderSchema,
  FolderWithItemsSchema,
  ProjectSchema,
  RecursiveFolderItemSchema,
  VersionedViewV2,
  WorkspaceSchema,
} from '@motion/zod/client'

import { viewIdSlug } from '~/areas/project-management/pages/pm-v3/views/hooks/view-id-slug'
import { useLookup } from '~/global/cache'
import { useFolders } from '~/global/rpc/folders'
import { useUriByRouteId } from '~/routing'
import { LexoRank } from 'lexorank'
import { useCallback, useMemo } from 'react'

export type UserFavoriteType = RecursiveFolderItemSchema['itemType']

export interface UserFavoriteBase {
  id: string
  name: string
  type: UserFavoriteType
  order: LexoRank
  url: string
  targetId: string
}

export interface UserFavoriteView extends UserFavoriteBase {
  type: 'VIEW'
  view: VersionedViewV2
  workspaceId: WorkspaceSchema['id'] | VersionedViewV2['type']
  folderId: FolderSchema['id'] | undefined
  projectId: ProjectSchema['id'] | undefined
}

export interface UserFavoriteFolder extends UserFavoriteBase {
  type: 'FOLDER'
  folder: FolderSchema
}

export interface UserFavoriteProject extends UserFavoriteBase {
  type: 'PROJECT'
  project: ProjectSchema
}

export type UserFavorite =
  | UserFavoriteView
  | UserFavoriteFolder
  | UserFavoriteProject

export type UseUserFavoritesReturnType = {
  items: UserFavorite[]
  folder: FolderWithItemsSchema | null
}

export const useUserFavorites = (): UseUserFavoritesReturnType => {
  const { data: folders } = useFolders()
  const getRouteUri = useUriByRouteId()
  const lookup = useLookup()

  const folder = useMemo<UseUserFavoritesReturnType['folder']>(
    () => folders?.models.systemFolders['userFavorites'] ?? null,
    [folders]
  )

  const formatFavorites = useCallback(
    (item: RecursiveFolderItemSchema): UserFavorite | null => {
      const order = LexoRank.parse(item.order)

      const sharedProps = {
        id: item.id,
        targetId: item.itemId,
        order,
      } satisfies Partial<UserFavorite>

      if (item.itemType === 'VIEW') {
        const view = lookup('views', item.itemId)

        if (!view) {
          Sentry.captureException(
            new Error('Could not find view model when building user favorites'),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useUserFavorites',
              },
            }
          )

          return null
        }

        const viewId = viewIdSlug(view.id)

        let url: string
        let workspaceId: WorkspaceSchema['id'] | VersionedViewV2['type']
        let folderId: FolderSchema['id'] | undefined
        let projectId: ProjectSchema['id'] | undefined

        if (view.type === 'workspace') {
          if (!item.metadata) {
            Sentry.captureException(
              new Error('Metadata missing from favorited view'),
              {
                level: 'warning',
                extra: {
                  item,
                  view,
                },
                tags: {
                  position: 'useUserFavorites',
                },
              }
            )

            // If the metadata is missing for some reason, we can at least infer
            // the workspace it should have pointed to.

            item.metadata = {
              workspaceId: view.targetId,
            }
          }

          ;({ workspaceId, folderId, projectId } = item.metadata)

          if (projectId) {
            url = getRouteUri('workspace-project', {
              workspaceId,
              projectId,
              viewId,
            })
          } else if (folderId) {
            url = getRouteUri('workspace-folder', {
              workspaceId,
              folderId,
              viewId,
            })
          } else {
            url = getRouteUri('workspace-detail', {
              workspaceId,
              viewId,
            })
          }
        } else {
          workspaceId = view.type

          url = getRouteUri(view.type, {
            viewId,
          })
        }

        return {
          ...sharedProps,
          name: view.name,
          type: item.itemType,
          url,
          view,
          workspaceId,
          folderId,
          projectId,
        }
      }

      if (item.itemType === 'FOLDER') {
        const folder = lookup('folders', item.itemId)

        if (!folder) {
          Sentry.captureException(
            new Error(
              'Could not find folder model when building user favorites'
            ),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useUserFavorites',
              },
            }
          )

          return null
        }

        return {
          ...sharedProps,
          name: folder.name ?? '<Untitled Folder>',
          type: item.itemType,
          url: getRouteUri('workspace-folder', {
            workspaceId: folder.targetId,
            folderId: folder.id,
          }),
          folder,
        }
      }

      if (item.itemType === 'PROJECT') {
        const project = lookup('projects', item.itemId)

        if (!project) {
          Sentry.captureException(
            new Error(
              'Could not find project model when building user favorites'
            ),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useUserFavorites',
              },
            }
          )

          return null
        }

        return {
          ...sharedProps,
          name: project.name,
          type: item.itemType,
          url: getRouteUri('workspace-project', {
            workspaceId: project.workspaceId,
            projectId: project.id,
          }),
          project,
        }
      }

      return null
    },
    [getRouteUri, lookup]
  )

  const items = useMemo(() => {
    if (!folder) {
      return []
    }

    return folder.items
      .map(formatFavorites)
      .filter(Boolean)
      .sort(
        byProperty('order', (l, r) =>
          Compare.string(l.toString(), r.toString())
        )
      )
  }, [folder, formatFavorites])

  return {
    items,
    folder,
  }
}
