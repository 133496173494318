import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { byValue, Compare, type CompareFn } from '@motion/utils/array'

import { type ColumnDef } from '@tanstack/react-table'
import {
  DEFAULT_STRING_SORT,
  type FieldDefinition,
} from '~/areas/project-management/pages/pm-v3/fields'
import {
  type GroupableField,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { DateTime } from 'luxon'

// Fields for grouping
export const MeetingInsightsGroupableFields = [
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'host',
    label: 'Host',
  },
] as const satisfies GroupableField[]

// Fields for the table columns
export const MEETING_INSIGHTS_FIELDS = {
  title: {
    id: 'title',
    name: 'Name',
    size: { min: 400, default: 400 },
    sortFn: byValue<Pick<MeetingInsightsSchema, 'eventData'>, string | null>(
      (meetingInsight) => meetingInsight.eventData?.title ?? null,
      DEFAULT_STRING_SORT
    ),
  },
  actionItems: {
    id: 'actionItems',
    name: 'Action Items',
    size: 150,
  },
  startTime: {
    id: 'startTime',
    name: 'Time',
    size: 150,
    sortFn: byValue<MeetingInsightsSchema, string>(
      (meetingInsight) => meetingInsight.eventData?.start ?? '',
      DEFAULT_STRING_SORT
    ),
  },
  host: {
    id: 'host',
    name: 'Host',
    size: 150,
    sortFn: byValue<MeetingInsightsSchema, string | null>(
      (meetingInsight) =>
        meetingInsight.eventData?.organizer?.displayName ?? null,
      DEFAULT_STRING_SORT
    ),
  },
  attendees: {
    id: 'attendees',
    name: 'Attendees',
    size: 150,
    sortFn: byValue<MeetingInsightsSchema, number | null>(
      (meetingInsight) => meetingInsight.eventData?.attendees?.length ?? 0,
      Compare.numeric
    ),
  },
  date: {
    id: 'date',
    name: 'Date',
    size: 150,
    sortFn: byValue<MeetingInsightsSchema, string>(
      (meetingInsight) =>
        DateTime.fromISO(meetingInsight.eventData?.start ?? '').toISODate(),
      DEFAULT_STRING_SORT
    ),
  },
} satisfies Record<string, FieldDefinition<MeetingInsightsSchema>>

export const ClientOnlySortField = ['date', 'actionItems'] as const
export type ClientOnlySortField = (typeof ClientOnlySortField)[number]

export type SortableFields = Exclude<
  keyof typeof MEETING_INSIGHTS_FIELDS,
  keyof typeof ClientOnlySortField
>

export type SortField = {
  [key in keyof typeof MEETING_INSIGHTS_FIELDS]: (typeof MEETING_INSIGHTS_FIELDS)[key] extends {
    sortFn: CompareFn<any>
  }
    ? key
    : never
}[keyof typeof MEETING_INSIGHTS_FIELDS]
export type MeetingInsightsFieldId = keyof typeof MEETING_INSIGHTS_FIELDS

export type MeetingTreeListRowValueType =
  | {
      type: 'meetingInsights'
      value: MeetingInsightsSchema
    }
  | {
      type: 'date'
      value: string | DateTime
    }
  | {
      type: 'host'
      value: string
    }

export type TreeListColumn = ColumnDef<GroupedNode<MeetingTreeListRowValueType>>
