import { SearchSolid } from '@motion/icons'
import { Button, ScrollArea } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useMemo, useState } from 'react'

import { ExpandableSection } from './components'
import { FavoritesTreeview } from './favorites-treeview'
import { useFavoritesTreeviewItems } from './favorites-treeview/hooks'
import { type SortableFavoriteItem } from './favorites-treeview/types'

import { SidebarExpandablePanelHeader } from '../components/sidebar-expandable-panel'
import { useGlobalSidebarContext, useGlobalSidebarState } from '../hooks'

const MAX_QUICK_FAVORITES_COUNT = 10

export const FavoritesSection = () => {
  const { openedPanel, setOpenedPanel } = useGlobalSidebarContext()
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const { hasSearch } = useSidebarSearchContext()
  const items = useFavoritesTreeviewItems()

  if (hasSearch && items.length === 0) return null

  return (
    <ExpandableSection
      title='Favorites'
      isExpanded={sidebarState.sections.favorites}
      shouldRenderPanel={!hasSearch && openedPanel === 'favorites'}
      onToggleExpand={(state) => void toggleSidebarSection('favorites', state)}
      onToggleRenderPanel={(shouldOpen) =>
        void setOpenedPanel(shouldOpen ? 'favorites' : null)
      }
      renderPanelContent={
        !hasSearch &&
        items.length > MAX_QUICK_FAVORITES_COUNT &&
        (() => <AllFavoritesPanel items={items} />)
      }
    >
      <FavoritesTreeview
        items={hasSearch ? items : items.slice(0, MAX_QUICK_FAVORITES_COUNT)}
        disableDrag={hasSearch || items.length > MAX_QUICK_FAVORITES_COUNT}
      />
    </ExpandableSection>
  )
}

type AllFavoritesPanelProps = {
  items: SortableFavoriteItem[]
}

const AllFavoritesPanel = ({ items }: AllFavoritesPanelProps) => {
  const { hasSearch: hasSidebarSearch } = useSidebarSearchContext()
  const [search, setSearch] = useState('')

  const filteredItems = useMemo(
    () =>
      items
        .reduce<
          {
            item: SortableFavoriteItem
            score: number
          }[]
        >((acc, item) => {
          const score = computeSearchScore(item.label, search)

          if (!search || score > 0) {
            return [...acc, { item, score }]
          }

          return acc
        }, [])
        .sort(byProperty('score', Compare.numeric.desc))
        .map(({ item }) => item),
    [items, search]
  )

  return (
    <div className='w-full flex flex-col'>
      <div className='p-4 space-y-4'>
        <SidebarExpandablePanelHeader title='Favorites' />

        <div>
          <TextField
            size='normal'
            rounded='default'
            prefix={<SearchSolid />}
            placeholder='Search favorites...'
            value={search}
            onChange={setSearch}
            autoFocus
            showClearButton
          />
        </div>
      </div>

      <ScrollArea className='w-full'>
        <FavoritesTreeview
          items={filteredItems}
          disableDrag={hasSidebarSearch || !!search}
        />

        {filteredItems.length === 0 && (
          <div className='text-sm px-4'>
            <p className='font-semibold mb-1'>No results</p>
            <p className='mb-3'>Try a different search term.</p>
            <Button
              size='xsmall'
              sentiment='neutral'
              onClick={() => void setSearch('')}
            >
              Clear search
            </Button>
          </div>
        )}
      </ScrollArea>
    </div>
  )
}
