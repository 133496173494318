import { ArrowDownSolid, ArrowUpSolid } from '@motion/icons'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverTrigger,
} from '@motion/ui/base'

import { RowFieldSelect } from '~/areas/project-management/pages/pm-v3/header'

import { useViewState } from '../hooks/use-view-state'
import { type MeetingInsightsViewState } from '../view-types'

export function ConnectedGroupSortButton() {
  const [viewState, setViewState] = useViewState()

  const disabled = viewState.groupBy.fields.length === 0

  // Only one type of 'order' for now, which is direction based
  const order = viewState.groupBy.order.by

  function handleOnChange(value: MeetingInsightsViewState['groupBy']['order']) {
    setViewState((prev) => ({
      ...prev,
      groupBy: {
        ...prev.groupBy,
        order: value,
      },
    }))
  }

  function handleToggle() {
    handleOnChange({
      ...viewState.groupBy.order,
      by: viewState.groupBy.order.by === 'asc' ? 'desc' : 'asc',
    })
  }

  return (
    <ButtonGroup segmented>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={() => (
          <GroupByButtonPopover
            order={viewState.groupBy.order}
            onChange={handleOnChange}
          />
        )}
      >
        <Button
          disabled={disabled}
          sentiment={disabled ? 'neutral' : 'primary'}
          variant='outlined'
          size='small'
        >
          Sort Group
        </Button>
      </PopoverTrigger>
      <IconButton
        onClick={handleToggle}
        icon={order === 'asc' ? ArrowUpSolid : ArrowDownSolid}
        variant='outlined'
        size='small'
        disabled={disabled}
        sentiment={disabled ? 'neutral' : 'primary'}
      />
    </ButtonGroup>
  )
}

const OPTIONS: { id: string; label: string }[] = [
  {
    id: 'asc',
    label: 'Ascending',
  },
  {
    id: 'desc',
    label: 'Descending',
  },
]

type GroupByButtonPopoverProps = {
  order: MeetingInsightsViewState['groupBy']['order']
  onChange: (value: MeetingInsightsViewState['groupBy']['order']) => void
}

function GroupByButtonPopover({ onChange, order }: GroupByButtonPopoverProps) {
  return (
    <div className='bg-modal-bg text-semantic-neutral-text-default w-[240px] flex flex-col p-3 gap-3'>
      <span className='text-sm'>Sort group</span>

      <RowFieldSelect
        available={OPTIONS}
        selected={{
          id: order.by,
          label: OPTIONS.find((o) => o.id === order.by)?.label ?? '',
        }}
        onSelect={(option) =>
          onChange({ ...order, by: option.id as 'asc' | 'desc' })
        }
      />
    </div>
  )
}
