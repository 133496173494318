import { type MeetingActionItemSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useTaskByIdV2 } from '~/global/rpc/v2'

import { AutoscheduleToggle } from '../event/modals/action-item-modal/components/autoschedule-toggle'
import {
  ControlledAssigneeField,
  ControlledWorkspaceFolderProjectField,
  DeadlineField,
  DurationField,
} from '../event/modals/action-item-modal/fields'

type ActionItemInlineSectionProps = {
  actionItem: MeetingActionItemSchema
}

export function ActionItemInlineSection({
  actionItem,
}: ActionItemInlineSectionProps) {
  const isRejected = actionItem.hasBeenTriaged && actionItem.taskId == null
  const { data: taskSchema } = useTaskByIdV2(
    {
      id: actionItem.taskId,
    },
    {
      enabled: actionItem.taskId != null,
    }
  )

  const updateTask = useTaskUpdater()

  const task = taskSchema?.type === 'NORMAL' ? taskSchema : null
  const isAutoScheduled = task?.isAutoScheduled ?? true

  if (isRejected) {
    return null
  }

  return (
    <div className='flex flex-row gap-0.5 ml-5 items-center h-4'>
      <span>
        <ControlledWorkspaceFolderProjectField
          includeWorkspaceField={false}
          includeProjectField
          showFullPath
          size='xxsmall'
        />
      </span>
      <Divider />
      <span>
        <DurationField showLabel={false} size='xxsmall' />
      </span>
      <Divider />
      <span>
        <DeadlineField
          showLabel={false}
          showLeftIcon={false}
          showWarnButton={false}
          allowRemoveDeadline={false}
          sentiment='neutral'
          size='xxsmall'
        />
      </span>
      <Divider />
      <span>
        <ControlledAssigneeField showLabel={false} size='xxsmall' />
      </span>
      {task != null && (
        <>
          <Divider />
          <span className='ml-0.5'>
            <AutoscheduleToggle
              padding='none'
              variantOverride='off'
              size='xsmall'
              canToggle // task != null already checked
              checked={isAutoScheduled}
              onChange={(checked) => {
                if (task == null) return
                updateTask(task.id, {
                  isAutoScheduled: checked,
                })
              }}
            />
          </span>
        </>
      )}
    </div>
  )
}
const Divider = classed('div', {
  base: 'h-[80%] w-px bg-semantic-neutral-border-default rounded-full self-center',
})
