import { ChevronRightSolid } from '@motion/icons'
import type { COLOR } from '@motion/shared/common'
import { Button, FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { FolderColoredIcon } from '@motion/ui/project'
import { Sentry } from '@motion/web-base/sentry'
import type { FolderSchema, WorkspaceSchema } from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useLookup } from '~/global/cache'
import { WorkspaceBadge } from '~/global/components/badges'
import {
  ColorDropdown,
  WorkspacesTreeDropdown,
} from '~/global/components/dropdowns'
import { FolderLabel, WorkspaceLabel } from '~/global/components/labels'
import { useWorkspaceById } from '~/global/hooks'
import { useFindFolder } from '~/global/hooks/folders'
import { useFolders } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useMemo, useState } from 'react'

export type SaveHandlerData = {
  folderName: string
  parentFolderId: FolderSchema['id']
  selectedColor: COLOR
  selectedFolderId: FolderSchema['id'] | null
  selectedWorkspaceId: WorkspaceSchema['id'] | null
}

type BaseEditFolderModalProps = {
  close: () => void
  onSave: (data: SaveHandlerData) => void
  folderId?: FolderSchema['id']
  initialFolderName?: string
  initialSelectedColor?: COLOR
  initialSelectedParentFolderId?: FolderSchema['id']
  initialSelectedWorkspaceId?: WorkspaceSchema['id']
  isNewFolder?: boolean
}

export const BaseEditFolderModal = ({
  close,
  folderId,
  initialFolderName = '',
  initialSelectedColor = 'gray',
  initialSelectedParentFolderId,
  initialSelectedWorkspaceId,
  isNewFolder = false,
  onSave,
}: BaseEditFolderModalProps) => {
  const [selectedColor, setSelectedColor] =
    useState<COLOR>(initialSelectedColor)
  const [folderName, setFolderName] = useState(initialFolderName)
  const { data: folders, isInitialLoading } = useFolders()
  const findFolder = useFindFolder()
  const lookup = useLookup()

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<
    WorkspaceSchema['id'] | null
  >(initialSelectedWorkspaceId ?? null)

  const [selectedFolderId, setSelectedFolderId] = useState<
    FolderSchema['id'] | null
  >(initialSelectedParentFolderId ?? null)

  const selectedWorkspace = useWorkspaceById(selectedWorkspaceId)
  const selectedFolder = useMemo(
    () => (selectedFolderId ? lookup('folders', selectedFolderId) : null),
    [lookup, selectedFolderId]
  )

  const parentFolderId = useMemo(() => {
    if (
      isInitialLoading ||
      !folders ||
      (!selectedWorkspaceId && !selectedFolderId)
    )
      return null

    const matchedFolder = findFolder((folder) => {
      if (selectedFolderId) {
        return folder.id === selectedFolderId
      }

      return (
        folder.targetId === selectedWorkspaceId && folder.type === 'WORKSPACE'
      )
    })

    if (!matchedFolder) {
      Sentry.captureException(new Error('Could not find workspace folder'), {
        extra: {
          selectedWorkspaceId,
        },
        tags: {
          position: 'NewFolderModal',
        },
      })

      return null
    }

    return matchedFolder.id
  }, [
    findFolder,
    folders,
    isInitialLoading,
    selectedFolderId,
    selectedWorkspaceId,
  ])

  const onAction = async () => {
    if (!parentFolderId) return

    try {
      onSave({
        folderName: folderName.trim(),
        parentFolderId,
        selectedFolderId,
        selectedWorkspaceId,
        selectedColor,
      })
    } catch (e) {
      Sentry.captureException(
        new Error('Could not save folder', {
          cause: e,
        }),
        {
          extra: {
            folderName,
            parentFolderId,
            selectedFolderId,
            selectedWorkspaceId,
            selectedColor,
          },
          tags: {
            position: 'BaseEditFolderModal',
          },
        }
      )

      showErrorToast(e, 'Could not save folder')
    }
  }

  const disabled = !folderName.trim() || !parentFolderId

  return (
    <FormModal
      visible
      title={isNewFolder ? 'New folder' : 'Edit folder'}
      onClose={close}
      submitAction={{
        onAction,
        disabled,
        text: 'Save',
      }}
    >
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-2 self-stretch min-w-[400px]'>
          <ColorDropdown
            selectedColor={selectedColor}
            onChange={setSelectedColor}
          >
            <Button
              size='small'
              variant='outlined'
              sentiment='neutral'
              iconOnly
            >
              <FolderColoredIcon color={selectedColor} className='size-4' />
            </Button>
          </ColorDropdown>

          <div className='w-full'>
            <TextField
              autoFocus
              placeholder={
                isNewFolder || !initialFolderName
                  ? 'Folder name'
                  : initialFolderName
              }
              size='normal'
              value={folderName}
              onChange={setFolderName}
            />
          </div>
        </div>

        <WorkspacesTreeDropdown
          selectedId={selectedFolderId ?? selectedWorkspaceId ?? null}
          workspaceId={selectedWorkspaceId ?? undefined}
          computeDisabled={
            isNewFolder
              ? undefined
              : ({ item, workspace }) =>
                  workspace.id !== initialSelectedWorkspaceId ||
                  (!!folderId && item.itemId === folderId)
          }
          leafNodeType='FOLDER'
          onChange={(selected) => {
            setSelectedWorkspaceId(selected.workspaceId)
            setSelectedFolderId(selected.folderId)
          }}
        >
          <ModalFieldButton size='normal' icon={<WorkspaceBadge />}>
            {selectedWorkspace ? (
              <WorkspaceLabel value={selectedWorkspace} nameOnly />
            ) : (
              'Select workspace'
            )}

            {selectedFolder && (
              <>
                <span className='text-semantic-neutral-text-subtle'>
                  <ChevronRightSolid />
                </span>
                <FolderLabel value={selectedFolder} />
              </>
            )}
          </ModalFieldButton>
        </WorkspacesTreeDropdown>
      </div>
    </FormModal>
  )
}
