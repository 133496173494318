import {
  CheckSolid,
  ExclamationTriangleSolid,
  PencilSolid,
  XSolid,
} from '@motion/icons'
import {
  type MeetingActionItemSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { IconButton, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useActionItemForm } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/hooks'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { NAME_FIELD_RULE } from '~/global/rules'
import { type ReactNode, useState } from 'react'
import { useController } from 'react-hook-form'

type ActionItemTitleProps = {
  actionItem: MeetingActionItemSchema
  task?: TaskSchema | null
  disabled?: boolean
  isCancelStatus?: boolean
  notPermittedWorkspace?: boolean
  renderStatus: () => ReactNode
  onClick: () => void
}

export function ActionItemTitle({
  actionItem,
  task,
  isCancelStatus,
  disabled = false,
  notPermittedWorkspace = false,
  renderStatus,
  onClick,
}: ActionItemTitleProps) {
  const { form } = useActionItemForm()
  const { control } = form
  const { field: nameField } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })

  const isNewActionItem = isNoneId(actionItem.id)

  const taskExists = task != null
  const currentName = taskExists ? task.name : nameField.value
  const updateTask = useTaskUpdater()

  const [inEditMode, setInEditMode] = useState(isNewActionItem)

  const handleClickEdit = async () => {
    // entering edit mode
    setInEditMode(true)
  }

  const handleClickSave = async (value: string) => {
    // leaving edit mode/saving
    nameField.onChange(value)

    if (taskExists) {
      await updateTask(task.id, { name: value })
    }

    setInEditMode(false)
  }

  return (
    <TaskTitleContainer>
      <div className='shrink-0'>{renderStatus()}</div>
      {inEditMode ? (
        <EditTaskNameInput
          initialName={currentName}
          onCancel={() => setInEditMode(false)}
          onSave={(value) => handleClickSave(value)}
          onInputChange={(value) => nameField.onChange(value)}
          requireConfirmation={!isNewActionItem}
        />
      ) : (
        <div className='flex flex-row gap-1 min-w-0 flex-1'>
          <Tooltip
            renderContent={() =>
              notPermittedWorkspace
                ? "This task was created in a workspace you don't have access to."
                : null
            }
            asChild
          >
            <TaskTitle
              as='button'
              disabled={disabled}
              aria-disabled={disabled}
              onClick={onClick}
              type='button'
              strikethrough={isCancelStatus}
            >
              <span className='truncate'>{currentName}</span>
              {notPermittedWorkspace && (
                <ExclamationTriangleSolid className='size-3 flex items-center text-semantic-warning-text-default mt-1 shrink-0' />
              )}
            </TaskTitle>
          </Tooltip>
          {!isCancelStatus && !disabled && (
            <IconButton
              onClick={handleClickEdit}
              icon={PencilSolid}
              sentiment='neutral'
              variant='mutedBg'
              size='xsmall'
            />
          )}
        </div>
      )}
    </TaskTitleContainer>
  )
}

type EditTaskNameInputProps = {
  initialName: string
  onCancel: () => void
  onSave: (newName: string) => void
  onInputChange?: (value: string) => void
  requireConfirmation?: boolean
}

function EditTaskNameInput({
  initialName,
  onSave,
  onCancel,
  onInputChange,
  requireConfirmation = true,
}: EditTaskNameInputProps) {
  const [newName, setNewName] = useState<string>(initialName)

  const handleInputChange = (value: string) => {
    setNewName(value)

    if (!requireConfirmation) {
      onInputChange?.(value)
    }
  }

  return (
    <div className='flex items-center gap-1 shrink-0 flex-1'>
      <TextField
        value={newName}
        onChange={handleInputChange}
        size='small'
        autoFocus
        variant='minimal'
        fullWidth
      />
      {requireConfirmation && (
        <span className='flex gap-px shrink-0'>
          <IconButton
            onClick={() => onSave(newName)}
            icon={CheckSolid}
            sentiment='neutral'
            variant='mutedBg'
            size='xsmall'
            disabled={newName === initialName || newName.trim() === ''}
          />
          <IconButton
            onClick={onCancel}
            icon={XSolid}
            sentiment='neutral'
            variant='mutedBg'
            size='xsmall'
          />
        </span>
      )}
    </div>
  )
}

const TaskTitle = classed('span', {
  base: 'truncate min-w-0 [&>*]:whitespace-nowrap',
  variants: {
    strikethrough: {
      true: 'line-through text-semantic-neutral-text-muted',
      false:
        'underline underline-offset-auto decoration-solid decoration-skip-ink-none decoration-from-font text-semantic-primary-text-default',
    },
  },
})

const TaskTitleContainer = classed('span', {
  base: 'min-w-0 w-full text-[13px] leading-[16px] font-normal flex items-center gap-1.5',
})
