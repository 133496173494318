import { CheckSolid, XSolid } from '@motion/icons'
import { isNoneId } from '@motion/shared/identifiers'
import { ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'
import type { MeetingActionItemSchema } from '@motion/zod/client'

import { useActionItemForm } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/hooks'
import {
  useApproveActionItem,
  useRejectActionItem,
} from '~/areas/ai-hackerhouse/hooks/rpc'

export type ApproveRejectButtonsProps = {
  actionItem: MeetingActionItemSchema
  onAction?: (actionItemId: string) => void
}

export function ApproveRejectButtons({
  actionItem,
  onAction,
}: ApproveRejectButtonsProps) {
  const { form } = useActionItemForm()
  const { mutateAsync: rejectAsync, isLoading: rejectIsLoading } =
    useRejectActionItem()
  const { mutateAsync: approveAsync, isLoading: approveIsLoading } =
    useApproveActionItem()

  const name = form.watch('name')
  const workspaceId = form.watch('workspaceId')
  const assigneeUserId = form.watch('assigneeUserId')
  const duration = form.watch('duration')
  const dueDate = form.watch('dueDate')

  const canApprove =
    workspaceId != null &&
    !isNoneId(workspaceId) &&
    assigneeUserId != null &&
    duration != null &&
    dueDate != null &&
    name != null &&
    name.length > 0

  const handleReject = async () => {
    onAction?.(actionItem.id)

    if (isNoneId(actionItem.id)) {
      return
    }

    await rejectAsync({
      actionItemId: actionItem.id,
    })
  }

  const handleApprove = async () => {
    onAction?.(actionItem.id)

    await approveAsync({
      actionItemId: actionItem.id,
      meetingInsightsId: actionItem.meetingInsightsId,
      ...form.getValues(),
    })
  }

  const disabledApprove = !canApprove || approveIsLoading
  const disabledReject = rejectIsLoading

  return (
    <div className='self-center shrink-0'>
      <ButtonGroup size='small'>
        <Tooltip
          content={
            !canApprove ? 'Please select values for all fields' : 'Approve'
          }
          asChild
        >
          <IconButton
            disabled={disabledApprove}
            onClick={handleApprove}
            sentiment={disabledApprove ? 'neutral' : 'success'}
            variant='outlined'
            size='small'
            icon={CheckSolid}
            loading={approveIsLoading}
          />
        </Tooltip>

        <Tooltip content='Reject' asChild>
          <IconButton
            disabled={disabledReject}
            onClick={handleReject}
            sentiment={disabledReject ? 'neutral' : 'error'}
            variant='outlined'
            size='small'
            icon={XSolid}
            loading={rejectIsLoading}
          />
        </Tooltip>
      </ButtonGroup>
    </div>
  )
}
