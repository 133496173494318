import { makeLog } from '@motion/web-base/logging'

import { useMemo } from 'react'

import { useViewState } from '../hooks/use-view-state'
import { ClientOnlySortField, type SortField } from '../types'

const log = makeLog('meeting-insights/use-sort-by')

export function useSortBy() {
  // TBH might be worth just have a meeting insights version of this?
  const [viewState] = useViewState()

  return useMemo(():
    | {
        field: Exclude<SortField, ClientOnlySortField>
        direction: 'asc' | 'desc'
      }
    | undefined => {
    if (viewState.sortBy) {
      let field = viewState.sortBy.field

      if (viewState.sortBy.field === 'date') {
        field = 'startTime'
      }

      if (isValidSortField(field)) {
        return {
          field: field,
          direction: viewState.sortBy.direction,
        }
      }
      log.warn('Invalid sort field', viewState.sortBy.field)
    }

    return undefined
  }, [viewState.sortBy])
}

function isValidSortField(
  field: SortField
): field is Exclude<SortField, ClientOnlySortField> {
  return !ClientOnlySortField.includes(field)
}
