import { type CellContext } from '@tanstack/react-table'
import { type GroupedNode } from '~/areas/project-management/pages/pm-v3/grouping'
import { DateTimeCell } from '~/areas/project-management/pages/pm-v3/tree-list/cells/common-cells'
import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { DateGroupHeader } from '~/areas/project-management/pages/pm-v3/tree-list/cells/group-headers'
import { type ReactNode } from 'react'

import { ActionItemsCell } from './action-items-cell'
import { AttendeesCell } from './attendees-cell'
import { HostCell } from './host-cell'
import { TimeRangeCell } from './time-range-cell'

import { NameCell } from '../components/cells'
import { ExpandableHeader } from '../components/cells/expandable-header'
import {
  type MeetingInsightsFieldId,
  type MeetingTreeListRowValueType,
} from '../types'

type CellRenderFn = (
  info: CellContext<GroupedNode<MeetingTreeListRowValueType>, unknown>
) => ReactNode

export const CELLS: Record<MeetingInsightsFieldId, CellRenderFn> = {
  title(info) {
    const item = info.row.original.value
    const counter = info.row.index + 1

    let cell = null
    const canExpand = info.row.getCanExpand()

    if (item.type === 'meetingInsights') {
      cell = <NameCell counter={counter} meetingInsights={item.value} />
    } else if (item.type === 'date') {
      cell = <DateGroupHeader item={item} fieldName='Date' />
    } else if (item.type === 'host') {
      cell = <CellText>{item.value}</CellText>
    }

    if (canExpand) {
      return <ExpandableHeader cell={cell} info={info} />
    }

    return cell
  },
  actionItems(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (item.type === 'meetingInsights') {
      cell = <ActionItemsCell meetingInsights={item.value} />
    }

    return cell
  },
  date(info) {
    const item = info.row.original.value

    let cell = <span>-</span>

    if (item.type === 'meetingInsights') {
      cell = <DateTimeCell dateTime={item.value.startTime} />
    }

    return cell
  },
  startTime(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (item.type === 'meetingInsights' && item.value.eventData) {
      cell = (
        <TimeRangeCell
          startTime={item.value.eventData.start}
          endTime={item.value.eventData.end}
        />
      )
    }

    return cell
  },
  host(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (
      item.type === 'meetingInsights' &&
      item.value.eventData?.organizer != null
    ) {
      cell = <HostCell organizer={item.value.eventData.organizer} />
    }

    return cell
  },
  attendees(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (
      item.type === 'meetingInsights' &&
      item.value.eventData?.attendees != null
    ) {
      cell = <AttendeesCell attendees={item.value.eventData.attendees} />
    }

    return cell
  },
}
