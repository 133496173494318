import { DB, readPersistedStateMany } from '@motion/web-common/storage'

import { DEFAULT_VIEW_ID } from '~/areas/project-management/pages/pm-revamp/team-schedule/context'
import {
  type PageType,
  type RouteData,
} from '~/areas/project-management/pages/pm-v3/routes/types'
import { ViewStateKey } from '~/areas/project-management/pages/pm-v3/view-state'
import { type MotionRoute } from '~/routing/api'

import { fromViewDefinition } from './loader/transform'
import { MeetingInsightsPage } from './meeting-insights-page'
import { MeetingInsightsShell } from './meeting-insights-shell'
import { MeetingInsightsViewPage } from './meeting-insights-view-page'

import { type MeetingsInsightsVersionedView } from '../../areas/ai-hackerhouse/meeting-insight/view-types'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    'meeting-insights': {
      meetingInsightsId: string
    }
    'meeting-insights-list': void
  }
}

const getDefaultAllCallsView = (): MeetingsInsightsVersionedView => {
  return {
    id: DEFAULT_VIEW_ID,
    schemaVersion: 2,
    // Name won't be used to display
    name: 'Default View',
    targetId: '',
    targetType: 'USER',
    isPrivate: true,
    createdTime: '',
    creatorUserId: null,
    updatedTime: null,
    deletedTime: null,
    definition: {
      filters: {},
      grouping: {
        fields: [
          {
            key: 'date',
            by: 'day',
          },
        ],
        order: {
          type: 'direction',
          by: 'desc',
        },
        hideEmptyGroups: true,
        dateRange: 'quarter',
      },
      sort: [
        {
          field: 'startTime',
          direction: 'DESC',
        },
      ],
      type: 'all-calls',
    },
  }
}

export const aiHackerhouseRoutes: MotionRoute[] = [
  {
    path: 'meeting-insights',
    element: <MeetingInsightsShell />,
    featureFlag: 'ai-meeting-bot',
    loader: async (args) => {
      await DB.open()

      const pageKey = 'meeting-insights_all-calls'
      const state = await readPersistedStateMany(DB.state, [ViewStateKey], {
        prefix: pageKey,
        notFound: 'null',
      })

      const defaultView = getDefaultAllCallsView()
      const viewState = fromViewDefinition(defaultView)

      if (state.get(ViewStateKey) == null) {
        state.set(ViewStateKey, viewState.view)
      }

      const storedViewState = state.get(ViewStateKey)

      // Some backfill logic for older versions of the view state
      if (storedViewState.type == null) {
        state.set(ViewStateKey, {
          ...storedViewState,
          page: 'meeting-insights',
          type: 'all-calls',
          groupBy: {
            ...storedViewState.groupBy,
            order: {
              type: 'direction',
              by: 'desc',
            },
          },
        })
      }

      return {
        key: pageKey,
        // `page` and `variant` not really used right now
        // Update PageType eventually to include 'meeting-insights'
        page: 'meeting-insights' as PageType,
        // How should we store the different variants of the meeting insights page? Discuss with Chris
        variant: 'all-calls' as RouteData['variant'],
        state,
      } satisfies Partial<RouteData>
    },
    children: [
      {
        id: 'meeting-insights-list',
        path: '',
        element: <MeetingInsightsViewPage />,
      },
      {
        id: 'meeting-insights',
        path: ':meetingInsightsId',
        element: <MeetingInsightsPage />,
      },
    ],
  },
]
