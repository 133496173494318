import { ArrowLeftOutline, LinkSolid } from '@motion/icons'
import { HttpError } from '@motion/rpc'
import { classed } from '@motion/theme'
import { Button, LoadingSpinner, showToast } from '@motion/ui/base'

import { ConnectedPermissionPopover } from '~/areas/ai-hackerhouse/automation/connected-permission-popover'
import {
  ActionItemsSection,
  MeetingSummary,
} from '~/areas/ai-hackerhouse/components'
import { NotesSection } from '~/areas/ai-hackerhouse/components/notes-section'
import { useMeetingInsightsQuery } from '~/areas/ai-hackerhouse/hooks/rpc'
import {
  MeetingRecordingPlayer,
  useMeetingInsightsParams,
} from '~/areas/ai-hackerhouse/meeting-insight'
import { copyToClipboard } from '~/localServices/clipboard'
import { useUriByRouteId } from '~/routing'
import { useEffect, useState } from 'react'

export function MeetingInsightsPage() {
  const { meetingInsightsId } = useMeetingInsightsParams()
  const buildUri = useUriByRouteId()

  const {
    data: meetingInsightsQuery,
    isLoading,
    isError,
    error,
  } = useMeetingInsightsQuery(
    {
      filters: {
        ids: [meetingInsightsId ?? ''],
        meetingBotStatus: {
          operator: 'in',
          value: ['COMPLETED'],
        },
      },
    },
    {
      enabled: meetingInsightsId != null,
      retry(failureCount, error) {
        if (error instanceof HttpError) {
          return error.status !== 401
        }

        return failureCount === 3
      },
    }
  )

  function handleCopyLink() {
    if (meetingInsightsQuery == null || meetingInsightsQuery.length === 0)
      return

    copyToClipboard({
      text: new URL(
        buildUri('meeting-insights', {
          meetingInsightsId: meetingInsightsQuery[0].id ?? '',
        }),
        window.location.origin
      ).toString(),
    })

    showToast('success', 'Meeting Insights link copied to clipboard')
  }

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError) {
    return <ErrorPage error={error} />
  }

  const meetingInsights = meetingInsightsQuery[0]

  if (meetingInsights == null) {
    return (
      <ErrorPage
        error={
          new HttpError({
            status: 404,
            body: {
              message: `Meeting Insights not found`,
            },
            reqId: 'meeting-insights-not-found',
          })
        }
      />
    )
  }

  const event = meetingInsights.eventData

  return (
    <Page>
      <PageContent>
        <div>
          <Button
            url={buildUri('meeting-insights-list')}
            size='small'
            sentiment='neutral'
            variant='muted'
          >
            <ArrowLeftOutline />
            Recordings
          </Button>
        </div>

        <div className='flex justify-end'>
          <div className='flex flex-col gap-1'>
            <label className='text-2xs text-semantic-neutral-text-subtle'>
              Share notes & recording with
            </label>
            <ConnectedPermissionPopover meetingInsights={meetingInsights} />
          </div>
        </div>

        <MeetingRecordingPlayer meetingInsights={meetingInsights} />

        <div className='flex flex-col gap-4'>
          <div className='flex justify-between gap-2 items-center'>
            <h1 className='text-semantic-neutral-text-default text-2xl font-semibold'>
              {event?.title}
            </h1>

            <div className='flex gap-2 items-center'>
              <Button
                size='small'
                sentiment='neutral'
                variant='muted'
                onClick={handleCopyLink}
              >
                <LinkSolid />
                Copy link
              </Button>
            </div>
          </div>

          <MeetingSummary meetingInsights={meetingInsights} />

          {meetingInsights.actionItems.length !== 0 && (
            <ActionItemsSection meetingInsights={meetingInsights} />
          )}

          {meetingInsights.notes != null &&
            meetingInsights.notes.length > 0 && (
              <NotesSection meetingInsights={meetingInsights} />
            )}
        </div>
      </PageContent>
    </Page>
  )
}

function LoadingPage() {
  // Totally extra lol
  const [dots, setDots] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) return ''
        return prevDots + '.'
      })
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <CenteredPage>
      <LoadingSpinner size={36} />
      <h1 className='text-semantic-neutral-text-subtle font-medium'>
        Loading your meeting insight{dots}
      </h1>
    </CenteredPage>
  )
}

function ErrorPage({ error }: { error: unknown }) {
  let errorMsg = 'An error occurred while fetching the meeting'

  if (error instanceof HttpError) {
    if (error.status === 404) {
      errorMsg = error.message
    }
    if (error.status === 401) {
      errorMsg =
        'You do not have access to this meeting insight, please request access from the meeting organizer.'
    }
  }

  return (
    <CenteredPage>
      <h1 className='text-semantic-neutral-text-subtle font-medium text-2xl'>
        Oops! Something went wrong 🤯
      </h1>
      <p className='text-semantic-neutral-text-subtle text-sm text-center'>
        {errorMsg}
      </p>
    </CenteredPage>
  )
}

const PageContent = classed('div', {
  base: `
    grid grid-rows-[auto_1fr]
    gap-8
    max-w-4xl
  `,
})

const Page = classed('div', {
  base: `
    p-8
    h-full w-full overflow-auto
  `,
})

export const CenteredPage = classed(Page, {
  base: `
    flex flex-col gap-4 items-center justify-center
  `,
})
